/**
 * Created by android_wx on 17/2/16.
 */
export default {
    path: 'forgetpassword',

    getComponent(nextState, cb) {
        require.ensure([], (require) => {
            cb(null, require('./ForgetPassword').default)
        })
    }
}
