/**
 * Created by icepoint1999 on 12/11/2016.
 */
import {httpHelper} from './http-helper'
import { Spin } from 'antd'
import Loadable from 'react-loadable'
import filterXSS from 'xss'

const Fingerprint2 = Loadable({
    loader: () => import('fingerprintjs2'),
    loading: () => <Spin></Spin>
});

const download = require('downloadjs')
const Baidut = require('baidut');
Baidut.builder
  .setAppId("20180813000193771")
  .setKey("AxvT44L79rtE41rqm88M")
  .build();

class Datahelper {
    constructor() {
        this.session_key = Cookies.get('user_session_key')
    }

    /**
     * 从arrary 中删除一个数据
     * @param array
     * @param value
     * @returns {*}
     */
    delete_e_from_array(array, value) {
        const index = array.indexOf(value);
        if (index > -1) {
            return array.splice(index, 1)
        }
        return array
    }

    /**
     * 从Obj 中删除空数据
     * @param obj
     * @returns {*}
     */
    remove_empty_from_obj(obj) {
        Object.keys(obj).forEach((key) => (obj[key] === null || obj[key] === '' || obj[key] === ' ' || (obj[key] && obj[key].push && (obj[key][0] === ' ' || obj[key][0] === undefined)) || obj[key] === undefined) && delete obj[key]);
        return obj;
    }

    /**
     * 是否属于电话号码
     * @param phone
     * @returns {boolean}
     */
    isPhoneNumber(phone) {
        const myreg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
        if (!myreg.test(phone)) {
            return false;
        }
        return true
    }


    /**
     * 判断对象本身是否为空
     * @param obj
     * @returns {boolean}
     */
    isOwnEmpty(obj) {
        for (const name in obj) {
            if (obj.hasOwnProperty(name)) {
                return false;
            }
        }
        return true;
    }

    /**
     * 对字符串排序并且进行反转
     * @param a
     * @returns {string}
     */
    sortChars(a) {
        return a.split('').sort(
        ).reverse().join('');
    }

    /**
     * 格式化日期参数
     * @param date
     * @returns {string}
     * @constructor
     */
    FormatDate(date) {
        const month = date.getMonth()+1
        const day = date.getDate()
        return `${date.getFullYear()}-${month}-${day}`;
    }

    FormatDatetime(date) {
        const minutes = date.getMinutes() < 10 ? `0${date.getMinutes().toString()}` : date.getMinutes()
        const second = date.getSeconds() < 10 ? `0${date.getSeconds().toString()}` : date.getSeconds()
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${minutes}:${second}`
    }

    /**
     * 深度拷贝 防止相同绑定数据同时需改
     * @param oldObj
     * @returns {any}
     */

    deepCopy(oldObj) {
        let newObj = oldObj;
        if (oldObj && typeof oldObj === 'object') {
            newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};
            for (const i in oldObj) {
                if (oldObj.hasOwnProperty(i)) {
                    newObj[i] = this.deepCopy(oldObj[i]);
                }

            }
        }
        return newObj;
    }

    // 将财务数字转成千分位，正则好强大
    comdify(num){
        // let re=/\d{1,3}(?=(\d{3})+$)/g;
        // let n1=n.replace(/^(\d+)((\.\d+)?)$/,function(s,s1,s2){return s1.replace(re,"$&,")+s2;});
        // return n1;
        var source = String(num).split(".");//按小数点分成2部分
        source[0] = source[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)','ig'),"$1,");//只将整数部分进行都好分割
        return source.join(".");//再将小数部分合并进来
    }

    // 取数组的并集
    mergeArr(arr) {
        let result = []
        for (const item of arr) {
            result = result.concat(item)
        }

        return Array.from(new Set(result))
    }

    // 得到当前客户端的唯一标识
    getUniqueHash(callback){
        let self = this

        new Fingerprint2().get(function(result, components){
            self._browser_unique_hash = result
            callback && callback()
        });
    }

    // 已收到消息
    readMessage(push_message_id){
        const type = 'put'
        const url = '/api/send_messages/is_read.json'
        const params = {
            user_session_key: Cookies.get('user_session_key'),
            push_message_id: push_message_id
        }

        httpHelper.REQUEST(url, params, type)
    }

    // 得到浏览器的类型
    getBrowserType() {
        const userAgent = navigator.userAgent
        const isOpera = userAgent.indexOf('Opera') > -1
        if (isOpera) {
            return 'Opera'
        }
        if (userAgent.indexOf('Firefox') > -1) {
            return 'FF'
        }
        if (userAgent.indexOf('Chrome') > -1) {
            return 'Chrome'
        }
        if (userAgent.indexOf('Safari') > -1) {
            return 'Safari'
        }
        if ((userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera) || userAgent.indexOf('Trident') > -1) {
            return 'IE'
        }
    }

    /* 下载文件,
    *  修复了downloadjs中 文件名有中文时下载错误的问题
    *  大部分情况下面的downloadFile方法都可以解决，若是解决不了尝试使用这个方法
    *  IE10以上可以兼容，祝你好运
    * */

    download(data, strFileName, strMimeType) {
        var self = window, // this script is only for browsers anyway...
          defaultMime = "application/octet-stream", // this default mime also triggers iframe downloads
          mimeType = strMimeType || defaultMime,
          payload = data,
          url = !strFileName && !strMimeType && payload,
          anchor = document.createElement("a"),
          toString = function(a){return String(a);},
          myBlob = (self.Blob || self.MozBlob || self.WebKitBlob || toString),
          fileName = strFileName || "download",
          blob,
          reader;
        myBlob= myBlob.call ? myBlob.bind(self) : Blob ;

        if(String(this)==="true"){ //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
            payload=[payload, mimeType];
            mimeType=payload[0];
            payload=payload[1];
        }


        if(url && url.length< 2048){ // if no filename and no mime, assume a url was passed as the only argument
            fileName = url.split("/").pop().split("?")[0];
            anchor.href = url; // assign href prop to temp anchor
            // 若是有中文的时候则不会进来，所以会报错
            if(decodeURIComponent(anchor.href).indexOf(url) !== -1){ // if the browser determines that it's a potentially valid url path:
                var ajax=new XMLHttpRequest();
                ajax.open( "GET", url, true);
                ajax.responseType = 'blob';
                ajax.onload= function(e){
                    download(e.target.response, fileName, defaultMime);
                };
                setTimeout(function(){ ajax.send();}, 0); // allows setting custom ajax headers using the return:
                return ajax;
            } // end if valid url?
        } // end if url?


        //go ahead and download dataURLs right away
        if(/^data:([\w+-]+\/[\w+.-]+)?[,;]/.test(payload)){

            if(payload.length > (1024*1024*1.999) && myBlob !== toString ){
                payload=dataUrlToBlob(payload);
                mimeType=payload.type || defaultMime;
            }else{
                return navigator.msSaveBlob ?  // IE10 can't do a[download], only Blobs:
                  navigator.msSaveBlob(dataUrlToBlob(payload), fileName) :
                  saver(payload) ; // everyone else can save dataURLs un-processed
            }

        }else{//not data url, is it a string with special needs?
            if(/([\x80-\xff])/.test(payload)){
                var i=0, tempUiArr= new Uint8Array(payload.length), mx=tempUiArr.length;
                for(i;i<mx;++i) tempUiArr[i]= payload.charCodeAt(i);
                payload=new myBlob([tempUiArr], {type: mimeType});
            }
        }
        blob = payload instanceof myBlob ?
          payload :
          new myBlob([payload], {type: mimeType}) ;

        // 将database格式转成Bolb
        function dataUrlToBlob(strUrl) {
            var parts= strUrl.split(/[:;,]/),
              type= parts[1],
              decoder= parts[2] == "base64" ? atob : decodeURIComponent,
              binData= decoder( parts.pop() ),
              mx= binData.length,
              i= 0,
              uiArr= new Uint8Array(mx);

            for(i;i<mx;++i) uiArr[i]= binData.charCodeAt(i);

            return new myBlob([uiArr], {type: type});
        }

        // 保存方法
        function saver(url, winMode){
            if ('download' in anchor) { //html5 A[download]
                anchor.href = url;
                anchor.setAttribute("download", fileName);
                anchor.className = "download-js-link";
                anchor.innerHTML = "downloading...";
                anchor.style.display = "none";
                document.body.appendChild(anchor);
                setTimeout(function() {
                    anchor.click();
                    document.body.removeChild(anchor);
                    if(winMode===true){setTimeout(function(){ self.URL.revokeObjectURL(anchor.href);}, 250 );}
                }, 66);
                return true;
            }

            // handle non-a[download] safari as best we can:
            if(/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
                if(/^data:/.test(url))	url="data:"+url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
                if(!window.open(url)){ // popup blocked, offer direct download:
                    if(confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")){ location.href=url; }
                }
                return true;
            }

            //do iframe dataURL download (old ch+FF):
            var f = document.createElement("iframe");
            document.body.appendChild(f);

            if(!winMode && /^data:/.test(url)){ // force a mime that will download:
                url="data:"+url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
            }
            f.src=url;
            setTimeout(function(){ document.body.removeChild(f); }, 333);

        }//end saver

        if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
            return navigator.msSaveBlob(blob, fileName);
        }

        if(self.URL){ // simple fast and modern way using Blob and URL:
            saver(self.URL.createObjectURL(blob), true);
        }else{
            // handle non-Blob()+non-URL browsers:
            if(typeof blob === "string" || blob.constructor===toString ){
                try{
                    return saver( "data:" +  mimeType   + ";base64,"  +  self.btoa(blob)  );
                }catch(y){
                    return saver( "data:" +  mimeType   + "," + encodeURIComponent(blob)  );
                }
            }

            // Blob but not URL support:
            reader=new FileReader();
            reader.onload=function(e){
                saver(this.result);
            };
            reader.readAsDataURL(blob);
        }
        return true;
    };

    // 模板引擎实现，关键正则要写好，以及New Function
    // 虽然在react中没什么用，还是作为工具集写上
    render(template, data){
        // 1,分离出静态的代码
        const static_code = template.split(spliter)
        // 2,分离出动态的代码，然后处理下变量输出
        const dynamic_code = template.match(spliter).map(str => str.startsWith(`<%=`) ? `engies(${str.slice(3, -2).trim()})` : str.slice(2, -2).trim())
        // 3,将动态代码和静态代码还原到一起,对于直接输出的内容处理，js语法不动，也就是动态的不动，处理静态输出
        const full_code = static_code.map((txt, i) => i in dynamic_code ? `engies(${JSON.stringify(txt)})\n${dynamic_code[i]}\n` : `engies(${JSON.stringify(txt)})\n`).join('')
        const output = []
        // 4，将代码传递进去，生成一个function，然后传参执行这个function
        new Function(...Object.keys(data), "engies", full_code)(...Object.values(data), t => output.push(t))
        // 返回结果
        return output.join('')
    }

    // 改变后台传过来的时间格式（2017-01-01T00zssd）为（2017/01/01）
    changeDataFormat(str) {
        let date_str = str.substring(0, 10)
        date_str = date_str.replace(/-/g, '/')
        return date_str
    }

    // 比较两个对象是否相同
    isObjectValueEqual(a, b) {
        const aProps = Object.getOwnPropertyNames(a)
        const bProps = Object.getOwnPropertyNames(b)

        if (aProps.length !== bProps.length) {
            return false
        }

        for (let i = 0; i < aProps.length; i++) {
            const propName = aProps[i]
            if (Object.prototype.toString(a[propName]) === '[Object Object]' || Object.prototype.toString(b[propName]) === '[Object Object]') {
                this.isObjectValueEqual(a[propName], b[propName])
            }
            if (a[propName] !== b[propName]) {
                console.log(a[propName],b[propName])
                return false
            }
        }

        return true
    }

    // 得到按钮权限列表
    buttonIsShow(button_name, position, obj){
        this.page_buttons = JSON.parse(localStorage.getItem('pages_buttons'))

        obj = obj || this.page_buttons
        position = position || 1
        let button_list = button_name.split('-')

        let result = null
        if(obj){
            result = obj.find((item)=>{
                return item.description === button_list[position]
            })
        }

        if(result){
            if(position === button_list.length-1){
                return true
            }else if(!result.children){
                return true
            }else{
                return this.buttonIsShow(button_name,++position,result.children)
            }
        }else{
            return false
        }
    }

    // 得到融云云服务token
    getRongIMToken() {
        const type = 'get'
        const url = '/api/messages/token.json'
        const params = {
            user_session_key: Cookies.get('user_session_key')
        }
        httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                sessionStorage.setItem('Rong_token', res.data.data.token)
                this.connectRongIm(res.data.data.token)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    // 连接融云
    connectRongIm(token) {
        let self = this
        RongIMClient.connect(token, {
            onSuccess: function(userId) {
                console.log(`Login successfully.${userId}`)
            },
            onTokenIncorrect: function() {
                console.log('token无效')
            },
            onError: function(errorCode) {
                let info = ''
                switch (errorCode) {
                    case RongIMLib.ErrorCode.TIMEOUT:
                        info = '超时'
                        self.reconnect()
                        break
                    case RongIMLib.ErrorCode.UNKNOWN_ERROR:
                        info = '未知错误'
                        break
                    case RongIMLib.ErrorCode.UNACCEPTABLE_PaROTOCOL_VERSION:
                        info = '不可接受的协议版本'
                        break
                    case RongIMLib.ErrorCode.IDENTIFIER_REJECTED:
                        info = 'appkey不正确'
                        break
                    case RongIMLib.ErrorCode.SERVER_UNAVAILABLE:
                        info = '服务器不可用'
                        break
                }
                sessionStorage.setItem('Rong_Status','false')
                console.log(info)
                console.log(errorCode)
            }
        })
    }

    // 重连
    reconnect(){
        RongIMClient.reconnect({
            onSuccess(userid){
                console.log('重连成功，用户id:'+userid)
            },
            onTokenIncorrect(){
                console.log('token 无效')
            },
            onError(errorCode){
                var info = '';
                switch (errorCode) {
                    case RongIMLib.ErrorCode.TIMEOUT:
                        info = '超时';
                        break;
                    case RongIMLib.ErrorCode.UNKNOWN_ERROR:
                        info = '未知错误';
                        break;
                    case RongIMLib.ErrorCode.UNACCEPTABLE_PaROTOCOL_VERSION:
                        info = '不可接受的协议版本';
                        break;
                    case RongIMLib.ErrorCode.IDENTIFIER_REJECTED:
                        info = 'appkey不正确';
                        break;
                    case RongIMLib.ErrorCode.SERVER_UNAVAILABLE:
                        info = '服务器不可用';
                        break;
                }
                console.log(info)
            }
        })
    }

    // 写cookies
    setCookie(name, value) {
        const days = 30
        const exp = new Date()
        exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000)
        let encode_result = encodeURIComponent(String(value))
          .replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g, decodeURIComponent);

        document.cookie = `${name}=${encode_result};path=/;`
    }

    // 读取cookies
    getCookies(name) {
        let arr, reg = new RegExp(`(^|)${name}=([^;]*)(;|$)`)
        if (arr = document.cookie.match(reg)) {
            return decodeURIComponent(arr[2])
        } else {
            return null
        }
    }

    // 删除cookies
    delCookies(name) {
        const exp = new Date()
        exp.setTime(exp.getTime() - 1)
        const cval = this.getCookies(name)
        if (cval) {
            document.cookie = `${name}=${cval};expires=${exp.toUTCString()}`
        }
    }

    // 导出excel
    exportFileExcel(output) {
        const parser = new DOMParser()
        const htmldoc = parser.parseFromString(output, 'text/html')
        const body_str = htmldoc.documentElement.getElementsByTagName('body')[0].innerHTML
        const head_str = htmldoc.documentElement.getElementsByTagName('head')[0].innerHTML
        const myiframe = document.createElement('iframe')
        document.body.appendChild(myiframe)
        myiframe.contentWindow.document.body.innerHTML = body_str
        myiframe.contentWindow.document.head.innerHTML = head_str
        const table = myiframe.contentWindow.document.getElementById('testTable')

        const uri = 'data:application/vnd.ms-excel;base64,'
        const template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
        const base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
        const format = function (s, c) { return s.replace(/{(\w+)}/g, (m, p) => c[p]) }

        const ctx = { worksheet: 'Worksheet', table: table.innerHTML }
        const url = uri + base64(format(template, ctx))
        window.open(url, '_blank')
    }

    // 得到元素距离顶部的距离
    getObjOffsetTop(obj){
        let top = obj.offsetTop
        let current = obj.offsetParent
        if(current){
            top+=current.offsetTop
            current = current.offsetParent
        }

        return top
    }

    // 返回对象的属性(keys)数组
    objectKeys(obj) {
        const arr = []
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                arr.push(key)
            }
        }
        return arr
    }

    // 返回对象的属性值(values)数组
    objectValues(obj) {
        const arr = []
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                arr.push(obj[key])
            }
        }
        return arr
    }

    // 返回对象的键值对数组([key, value])
    objectEntries(obj) {
        const arr = []
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                arr.push([key, obj[key]])
            }
        }
        return arr
    }

    // 将url转成二进制对象
    dataUrlToBlob(strUrl) {
        let myBlob = (window.Blob || window.MozBlob || window.WebKitBlob || toString)
        let parts= strUrl.split(/[:;,]/),
          type= parts[1],
          decoder= parts[2] == "base64" ? atob : decodeURIComponent,
          binData= decoder( parts.pop() ),
          mx= binData.length,
          i= 0,
          uiArr= new Uint8Array(mx);

        myBlob= myBlob.call ? myBlob.bind(self) : Blob ;
        for(i;i<mx;++i) uiArr[i]= binData.charCodeAt(i);

        return new myBlob([uiArr], {type: type});
    }

    // 下载pdf文件，download方法不知道为什么下载不了pdf文件
    downloadFile(url, file=null) {
        console.log(url)
        this.effectiveDownloadFile(url, file)

        // if(url.split('=')[1]){
        //     // 七牛可以直接根据后面的attname下载,不是七牛的就用我们常规的方法
        //     // 其实else下面可以直接使用上面的download方法
        //     window.open(url)
        //     Cookies.get('current_role') === 'cms' && file && Utils.httpHelper.countDownloadTimes(file.id || file.attachment_id);
        // }else if(this.getBrowserType() === 'IE'){
        //     if(url.endsWith('jpg') || url.endsWith('jpeg') || url.endsWith('png') || url.endsWith('gif')){
        //         // xls使用这种方式有问题，所以只图片使用这种方式
        //         download(url)
        //     }else{
        //         window.open(url)
        //     }
        //     Cookies.get('current_role') === 'cms' && file && Utils.httpHelper.countDownloadTimes(file.id || file.attachment_id);
        // }else{
        //     let xhr = new XMLHttpRequest()
        //     xhr.responseType = 'blob'
        //     xhr.onload = () => {
        //         let a = window.document.createElement('a')
        //         a.download = url.slice(url.lastIndexOf('/') + 1)
        //         a.href = window.URL.createObjectURL(xhr.response)
        //         a.style.display = 'none'
        //         window.document.body.appendChild(a)
        //         a.click()
        //         window.document.body.removeChild(a)
        //     }
        //     xhr.onreadystatechange = function(){
        //         if(this.readyState === 4){
        //             Cookies.get('current_role') === 'cms' && file && Utils.httpHelper.countDownloadTimes(file.id || file.attachment_id);
        //         }
        //     }
        //     xhr.open('GET', url)
        //     xhr.send()
        // }
    }

    // FIXME: 新版 - 文件访问全下载
    effectiveDownloadFile(url, file=null) {
        // url = decodeURI(url)
        console.log(url + '   test')
        this.changePreviewOrDownloadByUrl(url, false, file)
    }


    // 批量下载文件
    batchDownloadFile(urls) {
        for(var index in urls) {
            this.downloadFile(urls[index])
        }
    }

    // FIXME: 新版批量下载文件
    effectiveBatchDownloadFile(urls) {
        for(let index in urls) {
            window.setTimeout(() => {
                this.effectiveDownloadFile(urls[index])
            },800 * index)
        }
    }

    // 删除数据库
    deleteDatabase(){
        indexedDB.deleteDatabase('OSCO');
    }

    // 连接indexedDB数据库
    connectDatabase(version) {
        // window.indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB;
        // window.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.mozIDBTransaction;
        // window.IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.IDBKeyRange;
        // window.IDBCursor = window.IDBCursor || window.webkitIDBCursor || window.IDBCursor;

        const dbName = 'OSCO';
        const dbVersion = version;
        let idb;
        const dbconnect = window.indexedDB.open(dbName, dbVersion);

        dbconnect.onsuccess = (e) => {
            idb = e.target.result
            console.log('连接数据库成功')
        }

        dbconnect.onerror = () => {
            console.log('连接数据库失败')
            this.deleteDatabase()
        }

        dbconnect.onupgradeneeded = (e) => {
            console.log('版本变化了')
            console.log(dbVersion)
            idb = e.target.result
            const tx = e.target.transaction
            const name = 'Users'
            const options = {
                keyPath: 'UserID',
                autoIncrement: false
            }
            const store = idb.createObjectStore(name, options)
            console.log('对象仓库创建成功')
        }

        console.log(dbconnect)
    }

    // 添加数据到数据库中
    addDataToDatabase(version, id, data) {
        // window.indexedDB = window.indexedDB||window.webkitIndexedDB||window.mozIndexedDB;
        // window.IDBTransaction = window.IDBTransaction||window.webkitIDBTransaction||window.mozIDBTransaction;
        // window.IDBKeyRange = window.IDBKeyRange||window.webkitIDBKeyRange||window.IDBKeyRange;
        // window.IDBCursor = window.IDBCursor||window.webkitIDBCursor||window.IDBCursor;

        const dbName = 'OSCO';
        const dbVersion = version;
        let idb;
        const dbconnect = window.indexedDB.open(dbName, dbVersion);

        dbconnect.onsuccess = (e) => {
            idb = e.target.result
            console.log('连接数据库成功')
            const tx = idb.transaction(['Users'], 'readwrite')
            const store = tx.objectStore('Users')
            const value = {
                UserID: id,
                data
            }
            const req = store.put(value)
            req.onsuccess = () => {
                console.log('数据保存成功')
            }
            req.onerror = () => {
                console.log('数据保存失败')
            }
        }

        dbconnect.onerror = () => {
            console.log('连接数据库失败')
        }
    }

    // 从数据库中取数据
    getDataFromDatabase(version, id, callback) {
        // window.indexedDB = window.indexedDB||window.webkitIndexedDB||window.mozIndexedDB;
        // window.IDBTransaction = window.IDBTransaction||window.webkitIDBTransaction||window.mozIDBTransaction;
        // window.IDBKeyRange = window.IDBKeyRange||window.webkitIDBKeyRange||window.IDBKeyRange;
        // window.IDBCursor = window.IDBCursor||window.webkitIDBCursor||window.IDBCursor;

        const dbName = 'OSCO';
        const dbVersion = version;
        let idb;
        const dbconnect = window.indexedDB.open(dbName, dbVersion);

        dbconnect.onsuccess = (e) => {
            idb = e.target.result
            console.log('连接数据库成功')

            let storeNames = idb.objectStoreNames
            if(storeNames.contains('Users')){
                const tx = idb.transaction(['Users'], 'readwrite')
                const store = tx.objectStore('Users')

                const req = store.get(id)
                req.onsuccess = (e) => {
                    if (!e.target.result) {
                        console.log('没有符合的数据')
                    } else {
                        callback(e.target.result)
                    }
                }
                req.onerror = () => {
                    console.log('数据保存失败')
                }
            }else{
                callback({})
            }


        }

        dbconnect.onerror = () => {
            console.log('连接数据库失败')
        }
    }

    // 清空User Store
    deleteStore(version){
        const dbName = 'OSCO';
        const dbVersion = version;
        let idb;
        const dbconnect = window.indexedDB.open(dbName, dbVersion);
        dbconnect.onsuccess = (e) => {
            idb = e.target.result
            let storeNames = idb.objectStoreNames
            console.log('连接数据库成功')
            if(storeNames.contains('Users')){
                const tx = idb.transaction(['Users'], 'readwrite')
                const store = tx.objectStore('Users')
                store.clear()
                console.log('清除store成功')
            }

        }

        dbconnect.onerror = () => {
            console.log('连接数据库失败')
        }

        dbconnect.onupgradeneeded = (e) => {
            console.log('版本变化了')
            console.log(dbVersion)
            idb = e.target.result
            const tx = e.target.transaction
            const name = 'Users'
            const options = {
                keyPath: 'UserID',
                autoIncrement: false
            }
            const store = idb.createObjectStore(name, options)
            console.log('对象仓库创建成功')
        }
    }

    // 插入客服js片段
    insertCustomer(){
        (function(a,h,c,b,f,g){a["UdeskApiObject"]=f;a[f]=a[f]||function(){(a[f].d=a[f].d||[]).push(arguments)};g=h.createElement(c);g.async=1;g.src=b;c=h.getElementsByTagName(c)[0];c.parentNode.insertBefore(g,c)})(window,document,"script","https://liuyangbao.udesk.cn/im_client/js/udeskApi.js?1484649314884","ud");
        ud({
            "code": "114c58i7",
            "link": "https://liuyangbao.udesk.cn/im_client?web_plugin_id=17213",
            "isInvite": true,
            "mode": "inner",
            "color": "#307AE8",
            "pos_flag": "crm",
            "onlineText": "联系客服，在线咨询",
            "offlineText": "客服下班，请留言",
            "pop": {
                "direction": "top",
                "arrow": {
                    "top": 0,
                    "left": "80%"
                }
            },
            "css":{
                width:"30px",
                height:"30px",
                top: '500px'
            }
        });
        window.customerInserted = true
    }

    // 插入企业QQ代码
    insertWPQQ(){
        (function(a,h,c,b,f,g){g=h.createElement(c);g.async=1;g.src=b;g.defer=1;g.id=f;c=h.getElementsByTagName(c)[0];c.parentNode.insertBefore(g,c)})(window,document,"script","https://wp.qiye.qq.com/qidian/2852155126/a6d36fc039b75ff43c56acbd9d8cf97e","qd2852155126a6d36fc039b75ff43c56acbd9d8cf97e");
        window.insertQQ = true
    }

    xssFilter(str){
        let options = {
            stripIgnoreTag: true,
            stripIgnoreTagBody: ['script','iframe'],
            onTagAttr: function(tag, name, value, isWhiteAttr){
                if (tag === "img" && name === "src"){
                    return `src=${value}`;
                }else{
                    return null;
                }
            }
        }

        // 因为xss不能将&lt;这种的过滤掉,所以替换成原始的html状态,然后再过滤
        let xss_result = filterXSS(str.replace(/\&lt;/g,"<").replace(/\&gt;/g,">"),options)

        return xss_result
    }

    // 将文本翻译成目标文本
    translateWord(key, callback) {
        if (!key) {
            callback('')
            return
        }

        Baidut.translate(key)
          .then((result) => {
              callback(result.trans_result[0].dst)
              console.log("translated text: " + result.trans_result[0].dst);
          }, (e) => {
              console.log(e.code + ": " + e.message);
          });
    }

    // 用户直接输入链接时，判断是否有权限
    hasPower(component_url){
        // document.referrer为空表示是用户直接输入链接
        // 不再需要根据document.referrer判断了，因为在index.js 中可以使用onEnter回调
        let power_list = ['/opr/student/student_listsss']
        return power_list.includes(component_url)

        // if(!document.referrer){
        //     let power_list = ['/opr/student/student_lists']
        //     if(power_list.includes(document.location.pathname)){
        //         return require(component_url).default
        //     }else{
        //         window.history.back()
        //     }
        // }else {
        //     return require(component_url).default
        // }
    }

    filterOption(input, option){
        const result  = option.props.children && option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
        result && console.log(option.props.children)
        return result
    }
    filterFuzzyOption(input, option){
        const reg =/\s+/g
        return option.props.children && option.props.children.toString().toLowerCase().replace(reg,"").indexOf(input.toLowerCase().replace(reg,"")) >= 0
    }


    // 当页跳转
    goto(type) {
        $("html,body")
          .animate({
              scrollTop: $(type)
                .offset().top - 80
          }, 500);
    }

    // different env use different protocol
    getProtocol() {
        switch (process.env._ENV) {
            case 'prod':
            case 'production':
            case 'test':
            case 'testing':
                return 'auto';
            case 'sit':
                return 'auto-sit';
            default:
                return 'auto';
        }
    }

    toPercent(str) {
        if(str === null) {return str}
        if(str === undefined) {return ''}
        let n = (Number(str) * 100).toFixed(2)
        n += "%"
        return n
    }

    divide(a, b) {
        let n = 0
        if(a =='0' || b == '0') {
            return n.toFixed(4)
        } else {
            n = Number(a) / Number(b)
            return n.toFixed(4)
        }
    }

    // 预览图片
    perviewImage(file, callback) {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        if (file) {
            reader.readAsDataURL(file)
        }
    }

    //在测试环境改变正式环境的url
    changeDownloadUrl(fileUrl){
        if(process.env._ENV !== 'prod' && !fileUrl.includes('t-uploads')){
            console.log(process.env._ENV,'改变之前--11'+fileUrl)
            fileUrl = fileUrl.replace('uploads',process.env._ENV === 'dev' ? 'dit-uploads' : process.env._ENV+'-uploads')
        }

        if(fileUrl.includes('attname')){
            // 有attname的话，七牛做了处理,会自动下载
            fileUrl = fileUrl.substring(0,fileUrl.indexOf('?'))
        }

        return fileUrl
    }

    // 得到 文件的正确访问URL
    changePreviewOrDownloadByUrl(fileUrl, is_preview=true, file=null) {

        console.log(process.env._ENV + '文件路径' + 'fileUrl')
        if(process.env._ENV !== 'prod' && !fileUrl.includes('t-uploads')){
            console.log(process.env._ENV,'改变之前--22'+fileUrl)
            fileUrl = fileUrl.replace('uploads',process.env._ENV === 'dev' ? 'dit-uploads' : process.env._ENV+'-uploads')
        }

        if(fileUrl.includes('attname')){
            // 有attname的话，七牛做了处理,会自动下载
            fileUrl = fileUrl.substring(0, fileUrl.indexOf('?'))
        }

        if (is_preview) {
            this.getEffectiveUrl(fileUrl, file)
        } else {
            this.getEffectiveUrlByDown(fileUrl, file)
        }
    }

    // 资源访问权限控制请求拼接URL
    // 这个地方是直接传入url授权并打开预览
    getEffectiveUrl(url, file=null) {
        // FIXME: 本地上传之后预览
        let a_window = window.open()
        if (url.includes('blob:http')) {
            a_window.location.href = url
        } else {
            const params = { download_url: url }
            if (file != null) {
                params['attachment_id'] = file.id
            }

            Utils.httpHelper.REQUEST('/api/attachments/get_effective_url.json', params, 'get').then((res) => {
                if (res.data.status.code == "20000") {
                    console.log("获取验证之后的URL - preview ", res.data.data.effective_url)

                    a_window.location.href = decodeURI(res.data.data.effective_url)
                } else {
                    notification.error({
                        description: "资源鉴权认证失败"
                    })
                }
            })
        }
    }

    // 资源访问权限控制请求拼接URL
    // 修改时，注意file参数
    getEffectiveUrlByDown(url, file) {
        if (url.includes('blob:http')) {
            window.location.href = url
        } else {
            const params = { download_url: url }
            if (file != null) {
                params['attachment_id'] = file.id
            }
            let down_url = ''

            Utils.httpHelper.REQUEST('/api/attachments/get_effective_url.json', params, 'get').then((res) => {
                if (res.data.status.code == "20000") {
                    console.log("获取验证之后的URL - down : ", res.data.data.effective_url)

                    down_url = decodeURI(res.data.data.effective_url);

                    if(url.split('=')[1]) {
                        // 七牛可以直接根据后面的attname下载,不是七牛的就用我们常规的方法
                        // 其实else下面可以直接使用上面的download方法
                        // window.open(url)
                        window.location.href = down_url;
                        Cookies.get('current_role') === 'cms' && file != null && Utils.httpHelper.countDownloadTimes(file.id || file.attachment_id);

                    } else if (this.getBrowserType() === 'IE') {
                        if(down_url.endsWith('jpg') || down_url.endsWith('jpeg') || down_url.endsWith('png') || down_url.endsWith('gif')){
                            // xls使用这种方式有问题，所以只图片使用这种方式
                            download(down_url)
                        } else {
                            // window.open(url)
                            window.location.href = down_url;
                        }
                        Cookies.get('current_role') === 'cms' && file != null && Utils.httpHelper.countDownloadTimes(file.id || file.attachment_id);
                    } else {
                        let user_agent = navigator.userAgent
                        let isAndroid = user_agent.indexOf('Android') > -1 || user_agent.indexOf('Adr') > -1; //android终端
                        let isiOS = !!user_agent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                        // 手机端（手机端下载和web端不同，使用同样的方法会导致名字乱码或者无法下载）
                        if (isAndroid || isiOS) {
                            window.location.href = down_url
                        } else {
                            let xhr = new XMLHttpRequest()
                            xhr.responseType = 'blob'
                            xhr.onload = () => {
                                if((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
                                    let a = window.document.createElement('a')
                                    // a.download = down_url.slice(down_url.lastIndexOf('/') + 1)
                                    a.download = decodeURI(url.split('/').pop())
                                    // let content = xhr.response
                                    // let blob = new Blob(xhr.response)
                                    a.href = window.URL.createObjectURL(xhr.response)
                                    a.style.display = 'none'
                                    window.document.body.appendChild(a)
                                    a.click()
                                    window.document.body.removeChild(a)
                                    console.log('下载成功')

                                } else {
                                    console.log('下载失败,地址为：'+ down_url)
                                }
                            }
                            xhr.onreadystatechange = function(){
                                if(this.readyState === 4){
                                    Cookies.get('current_role') === 'cms' && file != null && Utils.httpHelper.countDownloadTimes(file.id || file.attachment_id);
                                }
                            }
                            xhr.onerror =() => {
                                console.log('下载失败,原因：'+ xhr.response)
                            }
                            xhr.open('GET', down_url)
                            xhr.send()
                        }
                    }

                } else {
                    notification.error({
                        description: "资源鉴权认证失败"
                    })
                }
            })
        }
    }

    //remove some special elements from word
    replaceSpecialElements(htmlContent){
       htmlContent = htmlContent.replace(/<\/?SPANYES[^>]*>/gi, "")
       // html = html.replace(/<(\w[^>]*)  class=([^|>]*)([^>]*)/gi, "<$1$3");  //  Remove  Class  attributes
       // html = html.replace(/<(\w[^>]*)  style="([^"]*)"([^>]*)/gi, "<$1$3");  //  Remove  Style  attributes
       // html = html.replace(/<(\w[^>]*)  lang=([^|>]*)([^>]*)/gi, "<$1$3");//  Remove  Lang  attributes
       // html = html.replace(/<\\?\?xml[^>]*>/gi, "");//  Remove  XML  elements  and  declarations
       // html = html.replace(/<\/?\w+:[^>]*>/gi, "");//  Remove  Tags  with  XML  namespace  declarations:  <o:p></o:p>
       // html = html.replace(/&nbsp;/, "");//  Replace  the  &nbsp;
       // html = html.replace(/\n(\n)*( )*(\n)*\n/gi, '\n');
       // Transform < P > to < DIV >
       // var re = new RegExp("(<P)([^>]*>.*?)(<//P>)", "gi");            //  Different  because  of  a  IE  5.0  error
       // html = html.replace(re, "<div$2</div>");
       return htmlContent
    }
}

let dataHelper = new Datahelper()
export {dataHelper}
