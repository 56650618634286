/**
 * Created by atyun on 17/1/17.
 */
import { notification } from 'antd'
import {httpHelper} from './http-helper'

class WorkFlowHelper {
    constructor() {
        this.session_key = Cookies.get('user_session_key')
    }

    // 得到客服ID
    // plan_id  方案ID
    getCustomService(plan_id, callback) {
        const type = 'get'
        const url = `/api/plans/${plan_id}/custom_service_rep.json`
        const params = {
            user_session_key: this.session_key
        }
        httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                callback(res.data.data)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    // 得到注册顾问id
    getRegistration(plan_id, callback) {
        const type = 'get'
        const url = `/api/plans/${plan_id}/registration_officer.json`
        const params = {
            user_session_key: this.session_key
        }
        httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                callback(res.data.data)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    // 得到入学顾问id
    getAdmissionAdvisor(plan_id, callback) {
        const type = 'get'
        const url = `/api/plans/${plan_id}/admission_advisor.json`
        const params = {
            user_session_key: this.session_key
        }
        httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                callback(res.data.data)
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    // 得到咨询顾问id
    getConsultor(plan_id, callback) {
        const type = 'get'
        const url = `/api/plans/${plan_id}/consultor.json`
        const params = {
            user_session_key: this.session_key
        }

        httpHelper.REQUEST(url, params, type).then((res) => {
            if (res.data.status.code === '20000') {
                callback(res.data.data)
            } else {
                notification.error({
                    description: '找不到该学生关联的咨询顾问'
                })
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    // 得到最闲客服
    getMostAppropriate(callback) {
        const url = '/api/work_flows/most_appropriate_user.json'
        const params = {
            user_session_key: this.session_key,
            role_id: '4185076293' // TODO 待从接口获取
        }

        httpHelper.REQUEST(url, params, 'get').then(res => {
            if (res.data.status.code === '20000') {
                callback(res.data.data)
            }
        }).catch(e => console.log(e))
    }
}

let workflowHelper = new WorkFlowHelper()
export {workflowHelper}
