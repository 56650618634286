exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".login__login--form___1cMQV{padding:0 6rem}.login__login--form___1cMQV section{margin-top:12rem}.login__login--form___1cMQV section input{font-size:14px}.login__login--form___1cMQV section h1{margin-bottom:2.66667rem;font-weight:500;color:#333;font-size:30px;font-weight:700}.login__login--form___1cMQV section button{font-size:14px;width:10rem;height:3rem;background-color:#1791f4;color:#fff}.login__login--form___1cMQV section .login__input--box___3yVDg{color:#666;margin-bottom:1.33333rem}.login__login--form___1cMQV section .login__input--box___3yVDg:nth-child(3){margin-bottom:1.33333rem !important}.login__login--form___1cMQV section .login__input--text___2JCtH{color:#333;margin:1.5rem 0 2.5rem 0}.login__login--form___1cMQV section .login__input--text___2JCtH .ant-checkbox-wrapper{color:#000}.login__login--form___1cMQV section .login__input--text___2JCtH .ant-checkbox-checked .ant-checkbox-inner{background-color:#fff !important}.login__login--form___1cMQV section .login__input--text___2JCtH .ant-checkbox-checked .ant-checkbox-inner:after{border-color:#f9be00}.login__login--form___1cMQV section .login__input--text___2JCtH .login__input--text_span___2wMB8{float:right;padding:0;color:#333;text-decoration:underline;font-size:12px;font-weight:bold}.login__login--form___1cMQV .login__footer___2hBx9{position:absolute;bottom:3.2rem}.login__login--form___1cMQV .login__footer___2hBx9 a{color:#000;font-size:14px;font-weight:bold}\n", ""]);

// Exports
exports.locals = {
	"login--form": "login__login--form___1cMQV",
	"input--box": "login__input--box___3yVDg",
	"input--text": "login__input--text___2JCtH",
	"input--text_span": "login__input--text_span___2wMB8",
	"footer": "login__footer___2hBx9"
};